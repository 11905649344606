var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tl-player-camera-selector my-0 pt-3",class:{
    'tl-player-camera-selector--scroll--mobile-height':
      _vm.hasScrollbar && _vm.$device.isMobile,
  },style:(_vm.containerStyle),on:{"mouseover":_vm.onContainerMouseOver,"mouseleave":_vm.onContainerMouseLeave,"click":_vm.toggleMenuForMobileDevices}},[_c('CameraCard',{key:_vm.selectedCamera.exid,staticClass:"ma-3",attrs:{"camera":_vm.selectedCamera,"token":_vm.token,"width":_vm.itemWidth + 40,"absolute":false,"highlighted":true,"showThumbnail":false,"icon":"fas fa-chevron-down"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded),expression:"isExpanded"}],ref:"content",staticClass:"tl-player-camera-selector__container mr-3 py-3 e-rounded-lg",style:(_vm.dropDownContainerStlye),attrs:{"data-test-id":"camera-selector"}},_vm._l((_vm.cameras),function(camera,index){return _c('CameraCard',{key:camera.exid,staticClass:"cursor-pointer ma-3",class:{
          'mt-0': index === 0,
          'mb-0': index === _vm.cameras.length - 1,
        },attrs:{"camera":camera,"token":_vm.token,"width":_vm.itemWidth,"absolute":false,"highlighted":_vm.isCameraSelected(camera),"data-test-id":"timeline-camera"},on:{"click":function($event){return _vm.onCameraClick(camera)}}})}),1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }