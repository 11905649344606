<template>
  <div
    class="tl-player-camera-selector my-0 pt-3"
    :class="{
      'tl-player-camera-selector--scroll--mobile-height':
        hasScrollbar && $device.isMobile,
    }"
    :style="containerStyle"
    @mouseover="onContainerMouseOver"
    @mouseleave="onContainerMouseLeave"
    @click="toggleMenuForMobileDevices"
  >
    <CameraCard
      :key="selectedCamera.exid"
      :camera="selectedCamera"
      :token="token"
      :width="itemWidth + 40"
      :absolute="false"
      :highlighted="true"
      :showThumbnail="false"
      icon="fas fa-chevron-down"
      class="ma-3"
    />
    <transition name="fade">
      <div
        v-show="isExpanded"
        ref="content"
        data-test-id="camera-selector"
        class="tl-player-camera-selector__container mr-3 py-3 e-rounded-lg"
        :style="dropDownContainerStlye"
      >
        <CameraCard
          v-for="(camera, index) in cameras"
          :key="camera.exid"
          :camera="camera"
          :token="token"
          :width="itemWidth"
          :absolute="false"
          :highlighted="isCameraSelected(camera)"
          data-test-id="timeline-camera"
          class="cursor-pointer ma-3"
          :class="{
            'mt-0': index === 0,
            'mb-0': index === cameras.length - 1,
          }"
          @click="onCameraClick(camera)"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { AdminCamera, Camera, AnalyticsEvent } from "@evercam/shared/types"
import CameraCard from "@evercam/shared/components/CameraCard"

export default Vue.extend({
  name: "TimelinePlayerCameraSelector",
  components: { CameraCard },
  props: {
    cameras: {
      type: Array as PropType<Array<Camera | AdminCamera>>,
      required: true,
    },
    selectedCamera: {
      type: Object as PropType<Camera | AdminCamera>,
      required: true,
    },
    targetTimestamp: {
      type: [String],
      default: undefined,
    },
    token: {
      type: String,
      required: true,
    },
    itemWidth: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      isMouseOver: false,
      isExpanded: false,
      hasScrollbar: false,
    }
  },
  computed: {
    containerStyle(): Record<string, any> {
      return {
        right: "22px",
      }
    },
    dropDownContainerStlye(): Record<string, any> {
      const minWidth = this.hasScrollbar ? this.itemWidth + 40 : this.itemWidth

      return {
        minWidth: `${minWidth}px !important`,
        float: "right",
      }
    },
  },
  mounted() {
    this.calculateContainerScrollState()
  },
  methods: {
    isCameraSelected(camera: Camera | AdminCamera): boolean {
      if (!camera) {
        return
      }

      return camera.exid === this.selectedCamera.exid
    },
    onContainerMouseOver() {
      if (this.$device.isMobile) {
        return
      }
      this.isMouseOver = true
      this.$setTimeout(() => {
        if (!this.isMouseOver) {
          return
        }

        this.calculateContainerScrollState()
        this.isExpanded = true
      }, 500)
    },
    onContainerMouseLeave() {
      this.isMouseOver = false
      this.$setTimeout(() => {
        if (this.isMouseOver) {
          return
        }

        this.isExpanded = false
      }, 600)
    },
    calculateContainerScrollState() {
      const expandedHeight = 550
      const contentHeight = this.$refs.content?.getBoundingClientRect()?.height
      this.hasScrollbar = contentHeight > expandedHeight
    },
    toggleMenuForMobileDevices() {
      if (!this.$device.isMobile) {
        return
      }
      this.isExpanded = !this.isExpanded
    },
    onCameraClick(c) {
      this.$emit("change", c)
      this.$root.$emit("analytics-event", {
        eventId: AnalyticsEvent.PlayerSelectCamera,
        params: { cameraId: c.exid },
      })
    },
  },
})
</script>

<style lang="scss">
@import "~@evercam/shared/styles/mixins";

.tl-player-camera-selector {
  @include custom-scrollbar(
    $thumb-color: #444,
    $background-color: #8c8c8c,
    $border-color: #5d5d5d,
    $width: 13px,
    $inset: 2px
  );
  &--scroll {
    &--mobile-height {
      @include fading-scroll-overflow($container-height: 220px !important);
    }
  }

  z-index: 3;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease-out;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }

  &__container {
    overflow-y: auto;
    background: rgba(17, 24, 39, 0.5);
    max-height: 500px;
    @include custom-scrollbar(
      $thumb-color: #444,
      $background-color: #8c8c8c,
      $border-color: #5d5d5d,
      $width: 13px,
      $inset: 2px
    );
  }
  .e-layout__top-right {
    z-index: 3;
  }
}
</style>
